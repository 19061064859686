import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { LoginService } from '../login/login.service';
import { Tblusuarios } from '../../models/tblusuarios';

@Injectable()
export class ForgotPasswordService {
  private beVerificar: string = AppSettings.baseEndpoint + '/usr';

  constructor(private http: HttpClient,
              public loginService: LoginService) {}

  usuario: Tblusuarios = new Tblusuarios();
  public verificarinfo(email: string, username: string): Observable<any> {
    return this.loginService.logingen(this.usuario).pipe(
      switchMap(response => {
        this.loginService.guardarUsuario(response.access_token);
        this.loginService.guardarToken(response.access_token);
        let usuario = this.loginService.usuario;
        const params = new HttpParams()
          .set('email', email)
          .set('username', username);
        return this.http.get<any>(`${this.beVerificar}/verificarinf`, { params: params, headers: this.loginService.addAuthorizationHeaders() }).pipe(
          catchError((e: HttpErrorResponse) => {
            this.loginService.noAuthorization(e);
            return throwError(this.loginService.validError(e));
          })
        );
      }),
      catchError(err => {
        return throwError(this.loginService.validError(err));
      })
    );
  }




}
