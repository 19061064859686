export class AppSettings {
  //PROD
  //public static baseEndpoint = 'https://helena.mx/yeims'; //Prod
  //public static baseEndpointContenedor = 'https://helena.mx/elfinder/home';

  //LOCAL
  //public static baseEndpointContenedor = 'http://localhost:8081/elfinder/home';

  //QA
  //public static baseEndpoint = 'https://helena.yeisystems.com.mx/yeims'; //Local;
  public static baseEndpoint = 'https://helenaqa.yeisystems.com.mx/yeims'; //Local;
  public static baseEndpointContenedor = 'https://helenaqa.yeisystems.com.mx/elfinder/home';

  public static confPorcionMex = 'PORCIONMEX';
  public static confTablanutrimental = 'tablanutrimental';

  //Estatus Calculadora

  public static estatusPorciones1 = 1;
  public static estatusIngredientes2 = 2;
  public static estatusAlergenos4 = 4;
  public static estatusAditivos3 = 3;
  public static estatusAditivosEvalua5 = 5;
  public static estatusNutrimental6 = 6;
  public static estatusDeclaracion7 = 7;
  public static estatusSellos8 = 8;
  public static estatusInformacion9 = 9;
  public static estatusEtiqueta10 = 10;
  public static estatusFinalizar11 = 11;

  //Configuración Sellos
  public static confCalorias = 'calorias';
  public static confAzucares = 'azucares';
  public static confSaturada = 'saturada';
  public static confTrnas = 'trans';
  public static confSodio = 'sodio';
  public static pi = 3.1416;
  public static areaPrincipalCil = 0.4;
  public static confPresRect = 'Rectangular';
  public static confPresCil = 'Cilindrico';
  public static confPresCir = 'Circular';
  public static confPresRectNo = '1';
  public static confPresCilNo = '2';
  public static confPresCirNo = '3';

  public static confSellomenor5 = '1 cm ANCHO X 1.11 cm ALTO'; //solo lleva número
  public static confSellomenor30 = '1 cm ANCHO X 1.11 cm ALTO'; //solo lleva número
  public static confSellomenor40 = '1.5 cm ANCHO X 1.66 cm DE ALTO'; //solo lleva número
  public static confSellomenor60 = '1.5 cm ANCHO X 1.66 cm DE ALTO'; //con sello y/o combinaciones
  public static confSellomenor100 = '2 cm ANCHO X 2.22 cm DE ALTO'; //con sello y/o combinaciones
  public static confSellomenor200 = '2.5 cm ANCHO X 2.77 cm DE ALTO'; //con sello y/o combinaciones
  public static confSellomenor300 = '3 cm DE ANCHO X 3.32 cm DE ALTO'; //con sello y/o combinaciones
  public static confSellomayor300 = '3.5 cm DE ANCHO X 3.88 cm DE ALTO'; //con sello y/o combinaciones

  public static confSelloId5 = -1; //solo lleva número
  public static confSelloId30 = -2; //solo lleva número
  public static confSelloId40 = -3; //solo lleva número
  public static confSelloId60 = 1; //con sello y/o combinaciones
  public static confSelloId100 = 2; //con sello y/o combinaciones
  public static confSelloId200 = 3; //con sello y/o combinaciones
  public static confSelloId300 = 4; //con sello y/o combinaciones
  public static confSelloIdmay300 = 5; //con sello y/o combinaciones

  public static confSelloTamanomenor33 = '1.5 mm';
  public static confSelloTamanomenor161 = '3.0 mm';
  public static confSelloTamanomenor645 = '4.5 mm';
  public static confSelloTamanomenor2580 = '6.0 mm';
  public static confSelloTamanomayor2580 = '12.0 mm';

  public static confSello2Tamanomenor50 = '1.5 mm';
  public static confSello2Tamanomenor200 = '2.0 mm';
  public static confSello2Tamanomenor750 = '3.0 mm';
  public static confSello2Tamanomenor1000 = '4.5 mm';
  public static confSello2Tamanomenor5000 = '5 mm';
  public static confSello2Tamanomayor5000 = '6 mm';

/*
  public static confSelloCaf5_30 = '1.233cm x 2.777cm o 1.1111cm x 0.2158cm';
  public static confSelloCaf30_40 = '2.9398cm x 0.1844cm o 1.6666cm x 0.3227cm ';
  public static confSelloCaf40_60 = '2.9398cm x 0.1844cm o 1.6666cm x 0.3227cm';
  public static confSelloCaf60_100 = '3.9197cm x 0.2466cm o 2.2222cm x 0.4316cm';
  public static confSelloCaf100_200 = '4.8996cm x 0.3077cm o 2.7777cm x 0.5386cm';
  public static confSelloCaf200_300 = '5.8796cm x 0.3688cm o 3.3333cm x 0.6455cm';
  public static confSelloCaf330 = '6.8596cm x 0.4311cm o 3.8888cm x 0.7544cm';

  public static confSelloEdul5_30 = '2.7777cm x 0.1233cm o 1.9598cm x 0.2158cm';
  public static confSelloEdul30_40 = '4.1666cm x 0.1844cm o 2.9398cm x 0.3227cm';
  public static confSelloEdul40_60 = '4.1666cm x 0.1844cm o 2.9398cm x 0.3227cm';
  public static confSelloEdul60_100 = '5.5555cm x 0.2466cm o 3.9197cm x 0.4316cm';
  public static confSelloEdul100_200 = '6.9444cm x 0.3077cm o 4.8996cm x 0.5386cm';
  public static confSelloEdul200_300 = '8.3333cm x 0.3688cm o 5.8796cm x 0.6455cm';
  public static confSelloEdul330 = '9.7222cm x 0.4311cm o 6.8595cm x 0.7544cm';
*/

  public static confSelloCaf5_30 = '19.538mm x 1.230mm o 11.0769mm x 2.153mm';
  public static confSelloCaf30_40 = '29.307mm x 1.846mm o 16.615mm x 3.230mm';
  public static confSelloCaf40_60 = '29.765mm x 1.875mm o 16.875mm x 3.281mm';
  public static confSelloCaf60_100 = '39.668mm x 2.466mm o 22.5mm x 4.316mm';
  public static confSelloCaf100_200 = '49.609mm x 3.125mm o 28.125mm x 5.468mm';
  public static confSelloCaf200_300 = '59.531mm x 3.75mm o 33.75mm x 6.562mm';
  public static confSelloCaf330 = '69.453mm x 4.375mm o 39.375mm x 7.656mm';

  public static confSelloEdul5_30 = '27.692mm x 1.230mm o 19.538mm x 2.153mm';
  public static confSelloEdul30_40 = '41.538mm x 1.846mm o 29.307mm x 3.230mm';
  public static confSelloEdul40_60 = '42.1875mm x 1.875mm o 29.765mm x 3.281mm';
  public static confSelloEdul60_100 = '56.25mm x 2.5mm o 39.687mm x 4.375mm';
  public static confSelloEdul100_200 = '70.312mm x 3.125mm o 49.609mm x 5.468mm';
  public static confSelloEdul200_300 = '84.375mm x 3.75mm o 59.531mm x 6.562mm';
  public static confSelloEdul330 = '98.437mm x 4.375mm o 69.453mm x 7.656mm';

  public static confLeyenda5_30 = -1;
  public static confLeyenda30_40 = 1;
  public static confLeyenda40_60 = 2;
  public static confLeyenda60_100 = 3;
  public static confLeyenda100_200 = 4;
  public static confLeyenda200_300 = 5;
  public static confLeyenda330 = 6;

  /*
  public static confSellomenor30 = '1 cm ANCHO X 1.11 cm ALTO';
  public static confSellomenor60 = '1.5 cm ANCHO X 1.66 cm DE ALTO';
  public static confSellomenor100 = '2 cm ANCHO X 2.22 cm DE ALTO';
  public static confSellomenor200 = '2.5 cm ANCHO X 2.77 cm DE ALTO';
  public static confSellomenor300 = '3 cm DE ANCHO X 3.32 cm DE ALTO';
  public static confSellomayor300 = '3.5 cm DE ANCHO X 3.88 cm DE ALTO';
   */

  public static confPageSizeOptions = [5, 10, 25, 100];
  public static confPagetotalRegistros = 0;
  public static confPagepaginaActual = 0;
  public static confPagetotalPorPagina = 10;

  public static confAne1 = 1;
  public static confAne2 = 2;
  public static confAne3 = 3;
  public static confAne4 = 4;
  public static confAne5 = 5;
  public static confAne6 = 6;
  public static confAne7 = 7;
  public static confAne8 = 8;
  public static confAne9 = 9;
  public static confAne10 = 10;
  public static confAne11 = 11; //XI Saborizantes Sinteticos
  public static confAne11_2 = 12; //XI Saborizantes Identicos al Natural

  public static confActiveTab = 1;

  public static idTipoVertical = 1;
  public static idTipoHorizontal = 2;

  public static confUni_g = 1;
  public static confUni_Kg = 2;
  public static confUni_ml = 3;
  public static confUni_L = 4;
  public static confUniValue = 1000;

  public static confTabNut = 'tablanutrimental';
  public static appU = 'angularapp';
  //public static appP = '12345'
  public static appP = 'q03wsp#t9r';

  public static confDibujo = 'tablaBebidas';

  //ORGANIZACION
  public static tblJerarquia = 1;
  public static dominioGlobal = 2; //es el id de la organicazion central en la tabla organizaciones
  public static dominioNivel = 2; //tbl_organizacion->org_intdominio
  public static MAXIMO_TAMANIO_BYTES = 512000;
  public static MAXIMO_TAMANIO_BYTES_ARCHIVO = 5000000;
  public static MAXIMO_TAMANIO_BYTES_EXCEL = 10000000;
  public static tamanioExcel = AppSettings.MAXIMO_TAMANIO_BYTES_EXCEL / 1000000;
  public static tamanioEnMb = AppSettings.MAXIMO_TAMANIO_BYTES / 1000000;

  public static rol0 = 'ADMON1';
  public static rol1 = 'ADMINISTRADOR';
  public static rol2 = 'CAPTURA';
  public static rol3 = 'CONSULTA';

  //HIDROGENADO
  public static parcHidro = 'parcialmente hidrogenado';
  public static art216bis = 'No esta conforme al Artículo 216bis de la Ley General de Salud';
  
}