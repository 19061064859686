import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Formatnom',
  pure: false
})
export class FormatnomPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    let rValue: string = value.toString()
    if(rValue.length > 0) {
      rValue = rValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return rValue;
    }
    return '0.0';
  }
}
