import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Tblempleados } from '../../models/tblempleados';
import { ForgotPasswordService } from './forgotpassword.service';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { Tblusuarios } from '../../models/tblusuarios';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: 'forgotpassword.component.html'
})
export class ForgotPasswordComponent {
  @Output() registroCompletado = new EventEmitter<void>();
  angForm: UntypedFormGroup;
  error: any;
  loading: boolean;
  empleado: Tblempleados = new Tblempleados();
  tblusuario: Tblusuarios = new Tblusuarios();
  emailPattern = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(private fb: UntypedFormBuilder,
    private forgotpasswordservice: ForgotPasswordService,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.createForm(); //validaciones
  }

  createForm() {//validaciones
    this.angForm = this.fb.group({
      userName: ['', Validators.required],
      empStrcorreo: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }

  verificarinformacion(){
    this.loading = true
    const correo = this.angForm.get('empStrcorreo').value;
    const username = this.angForm.get('userName').value;
    this.forgotpasswordservice.verificarinfo(correo, username).subscribe(response => {
      const coincide = response['coincide'];
      this.loginService.logout();
      if (coincide == false){
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Los datos no coinciden',
          showConfirmButton: true,
          confirmButtonColor: '#DC143C',
        })
        this.loading = false;
      }else if (coincide == true){
        Swal.fire({
          icon: 'success',
          text: 'La nueva contraseña se envió al correo electrónico',
          showConfirmButton: true,
          confirmButtonColor: '#DC143C',
        })
        this.registroCompletado.emit();
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      this.loginService.logout();
    });
  }

}
