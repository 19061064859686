import { Component, OnInit } from '@angular/core';
import { Tblusuarios } from '../../models/tblusuarios';
import swal from 'sweetalert2';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { Tblempleados } from '../../models/tblempleados';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegisterComponent } from '../register/register.component';
import { ForgotPasswordComponent } from '../forgotpassword/forgotpassword.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  titulo: string = 'Inicia sesión';
  usuario: Tblusuarios;
  error: any;
  tblempleado: Tblempleados[] = [];
  modalRef?: BsModalRef;
  loading: boolean;
  mostrarPassword = false;

  constructor(public loginService: LoginService, private router: Router, public modalService: BsModalService) {
    this.usuario = new Tblusuarios();
  }

    ngOnInit() {
      if (this.loginService.isAuthenticated()) {
        if(!this.loginService.isTokenExpired())
        {

          swal.fire('Login', `Hola ${this.loginService.usuario.tblEmpleado.empStrnombre} ya estás autenticado!`, 'info');
          this.router.navigate(['/dashboard']);
        }

      }
  }

    login(): void {
      this.loading = true;
      if (this.usuario.usrStrusuario == null || this.usuario.usrStrpassword == null) {
        swal.fire('Error Login', 'Usuario o password vacías!', 'error');
        this.loading = false;
        return;
      }

      this.loginService.login(this.usuario).subscribe(response => {

        this.loginService.guardarUsuario(response.access_token);
        this.loginService.guardarToken(response.access_token);
        let usuario = this.loginService.usuario;
        this.router.navigate(['/dashboard']);
        swal.fire('Login', `Hola ${usuario.usrStrnombre}, has iniciado sesión con éxito!`, 'success');
        this.loading = false;
      }, err => {
        this.loading = false;
        return throwError(this.loginService.validError(err));
      }
      );
    }
  mostrarModalCat(tblempleadoSend: Tblempleados | null = null) {
      tblempleadoSend = new Tblempleados();
    const initialState = {
      empleado: tblempleadoSend
    };
    this.modalRef = this.modalService.show(RegisterComponent, { initialState, class: 'modal-dialog-centered modal-dialog-scrollable modal-lg modal- center'});
    this.modalRef.content.closeBtnName = 'Cerrar';
    this.modalRef.content.registroCompletado.subscribe(() => {
      // Cerrar el modal de LoginComponent
      this.modalRef.hide();
    });
  }

  mostrarModalPass(tblempleadoSend: Tblempleados | null = null) {
    tblempleadoSend = new Tblempleados();
    const initialState = {
      empleado: tblempleadoSend
    };
    this.modalRef = this.modalService.show(ForgotPasswordComponent, { initialState, class: 'modal-dialog-centered modal-dialog-scrollable modal-lg modal- center' });
    this.modalRef.content.closeBtnName = 'Cerrar';
    this.modalRef.content.registroCompletado.subscribe(() => {
      this.modalRef.hide();
    });
  }

  togglePasswordVisibility(): void {
    this.mostrarPassword = !this.mostrarPassword;
  }
}
