import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-navs',
  templateUrl: './navs.component.html'
})
export class NavsComponent implements OnInit {

  constructor(public loginService: LoginService) { }

  ngOnInit(): void {
  }
}
