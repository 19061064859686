export class Tblempleados {
  empIntidempleado: number;
  ////empBolactivo: boolean;
  ////empBolbloqueado: boolean;
  ////empBoleliminado: boolean;
  empDatactualizacion: Date;
  empDatcumpleanios: Date;
  empDatfin: Date;
  empDatinicio: Date;
  empStrop2: string = '';
  empStrciudad: string = '';
  empStrcorreo: string = '';
  empStrcp: string = '';
  empStrdireccion: string = '';
  empStrimagen: string = '';
  empStrmaterno: string = '';
  empStrnoempleado: string = '';
  empStrnombre: string = '';
  empStrnombrecompleto: string = '';
  empStrop1: string = '';
  empStrpaterno: string = '';
  empStrtellocal: string = '';
  empStrtelmovil: string = '';
  empStrtitulo: string = '';
  empStrurlimg: string = '';
  clenIntidcatlenguaje: number;
  cestIntidestado: number;
  cpaiIntidpais: number;
  cgenIntidgenero: number;
  cmunIntidmunicipio: number;
  orgIntidorganizacion: number;
  roeIntidrelorgemp: number;
  retIntidemptra: number;
  //ctraIntidtrabajo: number;
  //usrIntiduseraudit: number = 0;
}
