import {NgModule} from '@angular/core';
import {YesnoPipe} from './pipes/yesno.pipe';
import {FormatnomPipe} from './pipes/formatnom.pipe';
import { FormatceroPipe } from './pipes/formatcero.pipe';

@NgModule({
  declarations: [
    FormatnomPipe,
    YesnoPipe,
    FormatceroPipe

  ],
  imports: [],
  exports:  [
    FormatnomPipe,
    YesnoPipe,
    FormatceroPipe
  ]
})
export class PipesModule {}
