import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Tblusuarios } from '../../models/tblusuarios';
import {AppSettings} from '../../app.settings';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _login: Tblusuarios;
  private _token: string;

  private httpHeaders: HttpHeaders = new HttpHeaders({
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient
    ,private router: Router) { }

    public get usuario(): Tblusuarios {
      if (this._login != null) {
        return this._login;
      } else if (this._login == null && sessionStorage.getItem('usuario') != null) {
        this._login = JSON.parse(sessionStorage.getItem('usuario')) as Tblusuarios;
        return this._login;
      }
      return new Tblusuarios();
    }

    public get token(): string {
      if (this._token != null) {
        return this._token;
      } else if (this._token == null && sessionStorage.getItem('token') != null) {
        this._token = sessionStorage.getItem('token');
        return this._token;
      }
      return null;
    }

    login(usuario: Tblusuarios): Observable<any> {
      const urlEndpoint =  AppSettings.baseEndpoint + '/oauth/token';
      const credenciales = btoa(AppSettings.appU + ':' + AppSettings.appP);

      const httpHeaders = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
        'Content-Type': 'application/x-www-form-urlencoded',  //'application/json',
        //'Content-Type':'application/json',
        'Authorization': 'Basic ' + credenciales
      });

      let params = new URLSearchParams();
      params.set('grant_type', 'password');
      params.set('username', usuario.usrStrusuario);
      params.set('password', usuario.usrStrpassword);
      //return this.http.post<any>(urlEndpoint, params.toString(), { headers: httpHeaders });

      return this.http.post<any>(urlEndpoint, params.toString(),
      { headers: httpHeaders }).pipe(
        catchError(e => {
          return throwError(this.validErrorLogin(e));
        })
      );
    }

  logingen(usuario: Tblusuarios): Observable<any> {
    const urlEndpoint = AppSettings.baseEndpoint + '/oauth/token';
    const credenciales = btoa(AppSettings.appU + ':' + AppSettings.appP);

    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Content-Type': 'application/x-www-form-urlencoded',  //'application/json',
      'Authorization': 'Basic ' + credenciales
    });

    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', "usrcontact");
    params.set('password', "b@Q2B0F$G@");
    //return this.http.post<any>(urlEndpoint, params.toString(), { headers: httpHeaders });

    return this.http.post<any>(urlEndpoint, params.toString(),
      { headers: httpHeaders }).pipe(
        catchError(e => {
          return throwError(this.validErrorLogin(e));
        })
      );
  }

    guardarUsuario(accessToken: string): void {
      let payload = this.obtenerDatosToken(accessToken);
      this._login = new Tblusuarios();
      this._login.usrStrnombre = payload.nombre;
      //this._login.apellido = payload.apellido;
      this._login.usrStremail = payload.email;
      this._login.usrStrusuario = payload.nomusuario;
      this._login.rol = payload.rol;
      this._login.access_token = payload.access_token;
      this._login.refresh_token = payload.refresh_token;
      this._login.expires_in = payload.expires_in;
      this._login.usrIntdominioactual = payload.domactual;
      this._login.usrIntidusuario = payload.userid;
      this._login.usrDominio = payload.domnombre;

      sessionStorage.setItem('usuario', JSON.stringify(this._login));
    }

    guardarToken(accessToken: string): void {
      this._token = accessToken;
      sessionStorage.setItem('token', accessToken);
    }

    obtenerDatosToken(accessToken: string): any {
      if (accessToken != null) {
        return JSON.parse(atob(accessToken.split(".")[1]));
      }
      return null;
    }

    isAuthenticated(): boolean {
      let payload = this.obtenerDatosToken(this.token);
      if (payload != null && payload.nomusuario && payload.nomusuario.length > 0) {
        return true;
      }
      return false;
    }

    hasRole(role: string): boolean {
      if (this.usuario.rol == role) {
        return true;
      }
      return false;
    }

    logout(): void {
      this._token = null;
      this._login = null;
      sessionStorage.clear();
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('usuario');

    }

    isTokenExpired(): boolean{
      let payload = this.obtenerDatosToken(this.token);
      let now = new Date().getTime() /1000;
      if(payload.exp < now)
      {
        return true;
      }
      return false;
    }

   getUsrID(): number {
    if (this.usuario != null) {
      if ( this.usuario.usrIntidusuario != null ) {
        return this.usuario.usrIntidusuario;
      }
      else {
        return -2;
      }
    }
    return -1;
  }

   getOrgID(): number {
    if (this.usuario != null) {
      if ( this.usuario.usrIntdominioactual != null ) {
        return this.usuario.usrIntdominioactual;
      }
      else {
        return -2;
      }
    }
    return -1;
  }


  getGlobal(): boolean {
    if (this.usuario != null) {
      if ( this.usuario.usrBolsistema != null ) {
        return this.usuario.usrBolsistema;
      }
      else {
        return false;
      }
    }
    return false;
  }

  validError(e: any): string{
    console.log('Error puro: ' + JSON.stringify(e));
    let errorMsg: string;

    try{
      let err = e;
      if(err.toString() === "TypeError: Cannot read properties of null (reading 'exp')")
      {
        return "Error de conexión, intenta más tarde. Consulta con el administrador.";
      }
    }
    catch{}

    if (e.error instanceof ErrorEvent) {
      console.log('instanceof Error');
      errorMsg = `Error: ${e.error.message}`;
    } else {
      errorMsg = this.getServerErrorMessage(e);
    }
    return errorMsg;
  }
  private getServerErrorMessage(error: HttpErrorResponse): string {
    console.log('Error getServerErrorMessage: ' + JSON.stringify(error));

    let code = error.status;
    let message = error.error.error;
    let message2 = error.message;
    let message3 = error.error.message;
    switch (code) {
      case 0: { //validacion de campos
        return `Error de conexión, consulta con el administrador: ${message2}`;
      }
      case 400: { //validacion de campos
        return `Error en validación de campos: ${message}`;
      }
      case 401: {
        return `No autenticado: ${message}`; //Unauthenticated
      }
      case 403: {
        return `Acceso denegado: ${message}`; //Access Denied
      }
      case 404: {
        return `Página no encontrada: ${message}`; //Not Found
      }
      case 409: {
        return `Error: ${message3}`; //
      }
      case 500: {
        return `Campo duplicado o viola la llave foránea: ${message2}`;
        //return `Internal Server Error: ${error.message}`;
      }
      case 504: {
        return `Tiempo de espera agotado: ${message}`; //Gateway Timeout
      }
      default: {
        return message;
      }

    }
  }


  validErrorLogin(error: any): any{
    console.log('Error puro: ' + JSON.stringify(error));
    let errorMsg: string = '';

    if (error.error instanceof ErrorEvent) {
      console.log('instanceof Error Login');
      Swal.fire('Error',`${error.error.message}`,'warning');
    }
    else if (error.error instanceof Error) {
      console.log('instanceof Error Login');
      Swal.fire('Error',`${error.error.message}`,'warning');
    }
     else {
      errorMsg = this.getServerErrorMessageLogin(error);
      Swal.fire('Error',`${errorMsg}`,'warning');
    }
    return errorMsg;
  }

  private getServerErrorMessageLogin(error: any): string {
    console.log('getServerErrorMessageLogin: ' + JSON.stringify(error));
    let code = error.status;
    let message = error.error.error_description;
    switch (code) {
      case 0: { //validacion de campos
        return `Error de conexión, consulta con el administrador`;
      }
/*
      case 400: { //validacion de campos
        return `Error en validación de campos: ${message}`;
      }
      case 401: {
        return `No autenticado: ${message}`; //Unauthenticated
      }
      case 403: {
        return `Acceso denegado: ${message}`; //Access Denied
      }
      case 404: {
        return `Página no encontrada: ${message}`; //Not Found
      }
      case 500: {
        return `Campo duplicado o viola la llave foránea: ${message}`;
      }
      case 504: {
        return `Tiempo de espera agotado: ${message}`; //Gateway Timeout
      }
*/
      default: {
        return message;
      }
    }
  }





  addAuthorizationHeaders() {
    if(!this.isAuthenticated()) {
      this.router.navigate(['/login']);
      this.logout();
      return null;
    }
    if(this.isTokenExpired()) {
      this.router.navigate(['/login']);
      this.logout();
      return null;
    }
    let token = this.token;
    if(token != null) {
      return this.httpHeaders.append('Authorization','Bearer ' + token);
    }
    return this.httpHeaders;
  }
  noAuthorization(e): boolean{
    if(e.status == 401){
      if(this.isAuthenticated()){
        this.logout();
      }
      if(e.status == 403){
        Swal.fire('Acceso denegado',`Hola ${this.usuario.usrStrnombre} no tienes acceso a este recurso`,'warning');
        this.router.navigate(['/dashboard']);
        return true;
      }
      this.router.navigate(['/login']);
      return false; //fmr
    }
    if(e.status == 403){
      Swal.fire('Acceso denegado',`Hola ${this.usuario.usrStrnombre} no tienes acceso a este recurso`,'warning');
      this.router.navigate(['/dashboard']);
      return true;
    }
    if(e.status == 404){
      Swal.fire('Acceso denegado',`Hola ${this.usuario.usrStrnombre} este enlace no esta permitido`,'warning');
      this.router.navigate(['/dashboard']);
      return true;
    }

    if(e.status == 0){ //el back no responde
      if(this.isAuthenticated()){
        this.logout();
      }
      this.router.navigate(['/login']);
      return false; //fmr
    }
    return false;
  }


  guardarUsuarioLogin(tblusuarios: Tblusuarios): void {
    let loginR = new Tblusuarios();
    loginR = this.usuario;
    this._login = new Tblusuarios();

    if(tblusuarios.usrStrusuario == null || tblusuarios.usrStrusuario.trim() == "")
    {
      this._login = loginR;
      sessionStorage.setItem('usuario', JSON.stringify(this._login));
    }
    else if(tblusuarios.usrDominio == null || tblusuarios.usrDominio.trim() == ""){
      this._login = tblusuarios;
      this._login.access_token = loginR.access_token;
      this._login.refresh_token = loginR.refresh_token;
      this._login.expires_in = loginR.expires_in;
      this._login.rol = loginR.rol;

      this._login.usrDominio = loginR.usrDominio;
      sessionStorage.setItem('usuario', JSON.stringify(this._login));
    }
    else{
      this._login = tblusuarios;
      this._login.access_token = loginR.access_token;
      this._login.refresh_token = loginR.refresh_token;
      this._login.expires_in = loginR.expires_in;
      this._login.rol = loginR.rol;

      this._login.usrStrnombre = loginR.usrStrnombre;
      this._login.usrStremail = loginR.usrStremail;
      this._login.usrStrusuario = loginR.usrStrusuario;
      this._login.usrIntdominioactual = loginR.usrIntdominioactual;
      this._login.usrIntidusuario = loginR.usrIntidusuario;
      this._login.usrDominio = loginR.usrDominio;
      sessionStorage.setItem('usuario', JSON.stringify(this._login));
    }


  }
}
